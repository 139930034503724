span.data-grid-container, span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid {
  /*table-layout: fixed;*/
  border-collapse: collapse;
  margin: auto;  
  width: 100%;
} 

.data-grid-container table td:first-child {
  position: sticky;
  left: 0; 
}

.data-grid-container table td:first-child .value-viewer {
  border-right: 1px solid #DDD;
  border-left: 1px solid #DDD;
  background: #ffffff;
}


.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition : background-color 0ms ease ;
}
.data-grid-container .data-grid .cell {
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition : background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #DDD;
  padding: 0;
  min-width: 70px;
}
.data-grid-container .data-grid .cell.selected {
  border: 1px double rgb(33, 133, 208);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
  max-width: 70px;
}

.data-grid-container .data-grid .cell.read-only {
  background: #ebebf7;
  color: #999;
  text-align: center;
}

.data-grid-container .data-grid .cell > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}


.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align:right;
  height: 100%;
  width: 100%;
  background: none;
  display: block;
}


.data-grid-container .data-grid .cell {
  vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
  text-align: end;
  padding: 3px;
  font-size: 12px;
}

.data-grid-container .data-grid .title .value-viewer  {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  background: #f3f4fa;
}

.data-grid-container .data-grid .level1 .value-viewer {
  font-weight: 700;
  font-style: italic;
  text-align: start;
  text-indent: 1em;
  text-transform: capitalize;  
}

.data-grid-container .data-grid .level2 .value-viewer {
  text-align: start;
  text-indent: 2em;
  text-transform: capitalize;
  font-size: 12px;
}

.data-grid-container .data-grid .level3 .value-viewer {
  text-align: start;
  text-indent: 3em;
  text-transform: capitalize;
  font-size: 12px;
}

.data-grid-container .data-grid .level4 .value-viewer {
  text-align: start;
  text-indent: 4em;
  text-transform: capitalize;
}

.data-grid-container .data-grid .level5 .value-viewer {
  text-align: start;
  text-indent: 5em;
  text-transform: capitalize;
}

.data-grid-container .data-grid .total1 .value-viewer {
  font-weight: 700;
  text-indent: 1em;
  text-align: start;
  text-transform: capitalize;
}

.data-grid-container .data-grid .total2 .value-viewer {
  text-indent: 2em;
  text-align: start;
  text-transform: capitalize;
}

.data-grid-container .data-grid .total3 .value-viewer {
  text-indent: 3em;
  text-align: start;
  text-transform: capitalize;
}

.data-grid-container .data-grid .total4 .value-viewer {
  font-weight: bold;
  text-indent: 4em;
  text-align: start;
  text-transform: capitalize;
}

.data-grid-container .data-grid .total5 .value-viewer {
  font-weight: bold;
  text-indent: 5em;
  text-align: start;
  text-transform: capitalize;
}

.data-grid-container .data-grid .totalHeading .value-viewer {
  font-weight: bold;
  text-align: start;
  text-indent: 1em;
  text-transform: capitalize;
  color: #333996;
}

.data-grid-container .data-grid .emptyRow .value-viewer {
  height: 25px;
}
