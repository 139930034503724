body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  bottom: 5px;
  position: fixed;
  left: 50%;
  margin-left: -60px;
}

.ag-theme-alpine .ag-header-cell {
  font-size: 13px !important;
}

.ag-theme-alpine .ag-header-group-text {
  font-size: 13px !important;
}

.ag-theme-alpine .ag-row {
  font-size: 12px !important;
  height: 36px !important;
}

.ag-theme-alpine .ag-cell {
  line-height: 36px !important;
}

/* Tiny Mce CSS */
.tox-tinymce {
  border-radius: 0px !important;
}

/* Temporary CSS for Compaince screen */
.full-width--input .MuiInputBase-input {
  width: 100% !important;
}

.MuiIconButton-root {
  padding: 0px !important;
  margin-left: 8px !important;
}

.MuiTabs-root {
  box-shadow: 0 3px 14px 0 rgba(122, 122, 122, 0.2) !important;
}

.MuiButton-contained {
  border: 1px solid #ffffff;
}

.MuiButton-contained:hover {
  color: #ffffff;
}

.ag-cell input {
  width: 100%;
  height: 40px;
}

.required .MuiFormLabel-root:after {
  content: '*';
  font-size: 14px;
  color: red;
  margin-left: 3px;
}
